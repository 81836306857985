import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import dayjsFr from "dayjs/locale/fr";
import { dayjs } from "element-plus";
import { createMetaManager } from "vue-meta";

dayjs.locale(dayjsFr);

import ElementPlus from "element-plus";
import fr from "element-plus/lib/locale/lang/fr";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

import "@/core/plugins/prismjs";
import VueEasyLightbox from "vue-easy-lightbox";
const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: "https://d8c29415b6604f6f8176561b2ebb8b8b@o4504320855375872.ingest.sentry.io/4504320862912512",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["egesparc.jugandautos.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

app.use(store);
app.use(router);
app.use(createMetaManager());
app.use(ElementPlus, {
  locale: fr,
});
app.use(VueEasyLightbox);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
  },
  locale: {
    clear: "Effacer",
    apply: "Appliquer",
    startsWith: "Commence par",
    contains: "Contient",
    notContains: "Ne contient pas",
    endsWith: "Finit par",
    equals: "Egale à",
    notEquals: "N'est pas égale à",
  },
});
store.commit("domain/setDomain", window.location.hostname);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
